<template>
  <div class="big">
    <template v-if="this.fromPath === 'classleave'">
      <div v-if="this.alldata.reply_status == '1' || this.alldata.reply_status == '2'
        " class="box1">
        <h4 style="display: flex; align-items: center; margin-top: 0.81rem">
          <img src="@/assets/img/person.png" alt="" style="width: 1.25rem; height: 1.25rem; margin-left: 0.88rem" />
          <span style="margin-left: 0.6rem">由{{ this.alldata.teacher_name
          }}<b v-if="this.alldata.reply_status == '2'">驳回</b><b v-if="this.alldata.reply_status == '1'">同意</b></span>
        </h4>
        <p style="font-size: 0.88rem; color: #333; margin-left: 1rem">
          {{ this.alldata.reply_content }}
        </p>
        <p>
          <span class="theader">审批时间</span>
          {{ this.alldata.reply_date }}
        </p>
      </div>
    </template>
    <template v-if="this.fromPath === 'teameasure'">
      <div v-if="this.alldata.reply_status == '1' || this.alldata.reply_status == '2'
        " class="box1">
        <h4 style="display: flex; align-items: center; margin-top: 0.81rem">
          <img src="@/assets/img/teacherper.png" alt="" style="width: 1.25rem; height: 1.25rem; margin-left: 0.88rem" />
          <span style="margin-left: 0.6rem">已<b v-if="this.alldata.reply_status == '2'">驳回</b><b
              v-if="this.alldata.reply_status == '1'">同意</b></span>
        </h4>
        <p style="font-size: 0.88rem; color: #333; margin-left: 1rem">
          {{ this.alldata.reply_content }}
        </p>
        <p>
          <span class="theader">审批时间</span>
          {{ this.alldata.reply_date }}
        </p>
      </div>
    </template>
    <div class="box">
      <div>
        <span class="theader" style="margin-top: 1.19rem">请假人</span>
        <span class="ctxt">{{ this.alldata.class_name }} {{ this.alldata.student_name }}</span>
      </div>
      <div>
        <span class="theader">请假类型</span>
        <span class="ctxt">{{ this.alldata.type_name }}</span>
      </div>
      <div>
        <span class="theader">开始时间</span>
        <span class="ctxt">{{ this.alldata.time_begin }}</span>
      </div>
      <div>
        <span class="theader">结束时间</span>
        <span class="ctxt">{{ this.alldata.time_end }}</span>
      </div>
      <div style="margin-bottom: 0.75rem; font-size: 0.88rem">
        <span class="theader" style="display: block">请假理由</span>
        <span style="margin-left: 1rem">
          {{ this.alldata.reasons }}
        </span>
      </div>
      <div>
        <img v-for="(item, index) in alldata.files" :key="index" :src="oss_url + item.file_url" style="width: 45%" />
      </div>
      <div>
        <span class="theader">审批人</span>
        <span class="ctxt">{{ this.alldata.teacher_name }}</span>
      </div>
      <div>
        <span class="theader">发起时间</span>
        <span class="ctxt">{{ this.alldata.create_time }}</span>
      </div>
      <p class="status">
        <img src="@/assets/img/Group 427319555@2x.png" v-if="this.alldata.reply_status == '0'" />
        <img src="@/assets/img/Group 427319554@2x.png" v-else-if="this.alldata.reply_status == '1'" />
        <img src="@/assets/img/Group 427319553@2x.png" v-else-if="this.alldata.reply_status == '2'" />
        <img src="@/assets/img/Group 427319556@2x.png" v-else-if="this.alldata.reply_status == '3'" />

        <span v-else-if="this.alldata.reply_status == '4'" class="change"><i> 已撤销 </i></span>
      </p>
    </div>
    <!-- 审核 -->
    <div class="box" v-if="this.fromPath == 'teameasure' && this.alldata.reply_status == 0 ">

      <div class="title">审核意见</div>

      <div class="radio">
        <van-radio-group v-model="she.reply_status" direction="horizontal">
          <van-radio name="1">同意</van-radio>&nbsp;&nbsp;&nbsp;&nbsp;
          <van-radio name="2">驳回</van-radio>
        </van-radio-group>
      </div>
      <van-divider />
      <div class="word">
        <div>审批留言</div>
        <textarea v-model="she.reply_content" placeholder="请输入审批留言"></textarea>
      </div>
    </div>

    <footer v-show="this.fromPath == 'classleave'">
      <img src="@/assets/img/button_revocation@2x.png" alt="" style="width: 100%; height: 100%"
        v-if="this.alldata.reply_status == 0" @click="handleCancel" />
      <img src="@/assets/img/faqi.jpg" alt="" style="width: 100%; height: 100%" v-if="this.alldata.reply_status == 4"
        @click="() => {
            this.$router.push('/leavecommit');
          }
          " />
    </footer>
    <!-- 老师操作按钮 -->
    <footer v-show="this.fromPath == 'teameasure' && this.alldata.reply_status == 0  ">

      <img src="../../assets/img/button_submit@2x.png" @click="shenhe()">

    </footer>
  </div>
</template>

<script>
import { LeaveDetail, Cancelleaver, momentsdel } from "@/api/leavelist";
import { Dialog } from "vant";
import { Notify } from 'vant';
export default {
  data() {
    return {
      id: "",
      alldata: [],
      oss_url: process.env.VUE_APP_OSS_URL,
      fromPath: "",

      she: {
        id: '',
        reply_status: '1',
        reply_content:'',
      }
    };
  },
  created() {
    this.fromPath = this.$route.query.name
    console.log(this.fromPath, '??');
    this.getDetail();
  },
  methods: {
    async getDetail() {
      await LeaveDetail("mobile/leave/detail", this.$route.query.id).then(
        (res) => {
          this.she.id = res.data.id,
          this.alldata = res.data;
          console.log(this.alldata.reply_status);
        }
      );
    },
    //确认是否撤销
    handleCancel() {
      Dialog.confirm({
        title: "提示",
        message: `等待老师审批中 确定撤销该请假？`,
      })
        .then(async () => {
          Cancelleaver("mobile/leave/cancel", { id: this.$route.query.id });
          (await this.getDetail()) && this.GetleaversList();
        })
        .catch(() => {
          // on cancel
        });
    },

    // 老师审核请假
    shenhe() {
      console.log(this.she);
      momentsdel(this.she).then(res => {
        console.log(res);
        if(res.code == 200){
            location.reload()
            Notify({ type: 'success', message: '审核成功' });
        }
      })
    }
  },
};
</script>

<style lang="less" scoped>
.big {
  padding: 1.19rem 0 0 1rem;
  background: #fafafa;
}

.box1 {
  background-color: #fff;
  height: 7.5rem;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
}

.box {
  width: 95%;
  position: relative;
  margin-top: 1.25rem;
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0 10px #e4e4e4;
  padding: 2vw;
  box-sizing: border-box;
}

.theader {
  height: 1.25rem;
  font-size: 0.88rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  line-height: 1.03rem;
  margin-bottom: 0.75rem;
  margin-left: 1rem;
}

span {
  display: inline-block;
}

.change {
  display: block;
  width: 4.25rem;
  height: 1.38rem;
  border-radius: 0rem 0.75rem 0rem 0.5rem;
  background: rgba(254, 107, 74, 0.15);
  opacity: 1;
  color: #fe6b4a;
  position: absolute;
  top: 0;
  right: -1rem;
  text-align: center;
}

.ctxt {
  font-size: 0.88rem;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #333;
  line-height: 1.03rem;
}

.theader::after {
  content: ":";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.status {
  position: absolute;
  top: -1.12rem;
  right: 1.13rem;
}

.status img {
  width: 4.25rem;
  height: 4rem;
}

footer {
  padding: 0 0.94rem;
  position: fixed;
  bottom: 10px;
  margin-left: -20px;

  img {
    width: 100%;
  }
}

.title::before {
  content: '*';
  color: red;
}

.radio {
  margin-top: 3vw;
  margin-left: 2vw;
}
.word{
  :first-child{
    margin-left: 2vw;
  };
  :nth-child(2){
    width: 95%;
    margin-left: 2vw;
    margin-top: 1vw;
    height: 30vw;
    border: 1px solid #dddddd;
    resize:none;
    padding: 1vw;
    box-sizing: border-box;
  }
}
</style>
